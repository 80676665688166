import React, { useState, useEffect } from "react";
import http from '../_api/http';
const Aashayein = () => {
  const [loading, setLoading] = useState(true);
  const [imageDescs, setImageDesc] = useState([]);
  const [image, setImage] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL;
  useEffect(() => {
    http.get('api/imageupload/ApiImageUploadList?image_subcategory_id=11&section=Aashayein').then((res) => {
      if (res.data.status === 200) {
        setImageDesc(res.data.image);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    http.get('api/imageupload/ApiImageUploadList?image_subcategory_id=12&section=Aashayein').then((res) => {
      if (res.data.status === 200) {
        setImage(res.data.image);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <section className="team-details">
        <div className="container">
          <div className="row justify-content-between">
            {loading
              ? <h4>View Aashayein Loading...</h4>
              :
              <>
                {imageDescs.map((item, i) =>
                  <div className="row" key={i}>
                    <div className="col-lg-6">
                      <div className="team-one__single">
                        <div className="team-one__image">
                          <img src={PUBLIC_URL + "uploadimage/" + item.image} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="team-details__content">
                        <h2 className="team-details__title title">{item.image_title}</h2>
                        <p className="team-details__text">
                          {item.image_description}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              </>
            }
          </div>
        </div>
      </section>
      <section className="gallery-one dup">
        <div className="container">
          <div className="row">
            {loading
              ? <h4>View Aashayein Images Loading...</h4>
              :
              <>
                {image.map((item, i) =>
                  <div className="col-lg-3 col-md-4" key={i}>
                    <div className="gallery-one__single">
                      <img src={PUBLIC_URL + "uploadimage/" + item.image} alt="" />
                      <div className="gallary-one__content">
                        <p className="gallary-one__gallary">
                          {item.image_title}
                        </p>
                      </div>
                      <a className="gallery-one__popup img-popup">
                        <i className="kipso-icon-plus-symbol"></i>
                      </a>
                    </div>
                  </div>
                )}

              </>
            }
          </div>
        </div>
      </section>
    </>
  );
}

export default Aashayein;
